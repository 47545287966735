<div id="project-base">
  <div id="project-sidebar" *ngIf="showSidebarAndHeader">
    <kuv-sidebar [logo]="'/assets/system/logo_sidebar.png'" [logo-sm]="'/assets/system/icon.png'" [routes]="routes"></kuv-sidebar>
  </div>
  <div id="project-header" *ngIf="showSidebarAndHeader">
    <ng-template #popTitle><label style="font-weight: 600;">Notificaciones</label></ng-template>
    <ng-template #popContent>
      <div class="d-flex flex-column gap-2 p-3 custom-sidebar" style="max-height: 500px; overflow: auto;">
        <div *ngIf="stockCriticos.length == 0">
          No existen notificaciones.
        </div>
        <div class="card p-1" *ngFor="let s of stockCriticos;" (click)="verStockCritico()">
          <div class="tag p-1 warning d-flex align-items-center gap-2" *ngIf="s.cantidad != 0">
            <i class="fas fa-exclamation-circle"></i>
            <div>El producto <b>{{s.producto.nombre}}</b> tiene {{s.cantidad}} unidad(es) de un mínimo de
              {{s.producto.stock_minimo}}.</div>
          </div>
          <div class="tag p-1 danger d-flex" *ngIf="s.cantidad == 0">
            <i class="fas fa-times-circle"></i>
            <div>El producto <b>{{s.producto.nombre}}</b> se ha quedado sin stock.</div>
          </div>
        </div>
        <a class="btn-link" style="cursor: pointer;" (click)="verStockCritico()">Ver detalles...</a>
      </div>
    </ng-template>

    <div *ngIf="sesion.sucursales.length > 0" class="mx-3">
      <select class="form-select" id="sucursal" [(ngModel)]="sesion.sucursalIndex" (change)="changeScope($event)">
        <ng-container *ngFor="let s of sesion.sucursales; let i = index">
          <option [value]="i">{{s.nombre}}</option>
        </ng-container>
      </select>
    </div>

    <button type="button" class="btn btn-kuv tool-btn position-relative" placement="bottom-right" [ngbPopover]="popContent"
      [popoverTitle]="popTitle" popoverClass="custom-popover">
      <i class="fas fa-bell"></i>
      <span class="position-absolute top-50 start-100 translate-middle badge rounded-pill text-bg-danger" *ngIf="stockCriticos.length != 0">{{stockCriticos.length}}</span>
    </button>

    <div ngbDropdown class="d-inline-block mx-3">
      <button class="btn btn-kuv" id="dropdownBasic1" ngbDropdownToggle>
        <i class="fas fa-user"></i><span class="mx-2 d-none d-sm-inline"> Mi Cuenta</span>
      </button>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="verPerfil()">
          <div class="d-flex gap-2">
            <span class="mr-4">
              <i class="fas fa-user-alt"></i>
            </span>
            <div>Ver Perfil</div>
          </div>
        </button>
        <button ngbDropdownItem (click)="changePass()">
          <div class="d-flex gap-2">
            <span class="mr-4">
              <i class="fas fa-key"></i>
            </span>
            <div>Cambiar Contraseña</div>
          </div>
        </button>
        <button ngbDropdownItem (click)="logout()">
          <div class="d-flex gap-2">
            <span class="mr-4">
              <i class="fas fa-sign-out-alt"></i>
            </span>
            <div>Cerrar Sesión</div>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div id="project-container" [ngClass]="{'no-sidebar': !showSidebarAndHeader}">
    <kuv-loading></kuv-loading>
    <kuv-alert></kuv-alert>
    <router-outlet></router-outlet>
  </div>
</div>